@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Roboto", sans-serif !important;
}
.flt {
  float: left;
  width: 100%;
}

.header_container {
  padding: 20px 30px;
}
.landing_container {
  position: relative;
}
.landing_cont .row {
  position: relative;
}
.landing_cont .row::before {
  content: "WHITE RICE";
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "Lato", sans-serif;
  font-size: 200px;
  z-index: -1;
  opacity: 0.1;
  color: black;
  -webkit-text-fill-color: rgb(255 255 255);
  -webkit-text-stroke-width: 8px;
  -webkit-text-stroke-color: rgb(234 76 137);
}

.dill {
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 6px;
  line-height: 20px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: calc(50% - 36px);
  left: -15px;
}
.landing_tag {
  font-size: 32px;
  color: rgb(0 24 61);
  margin-bottom: 80px;
}
.landing_desc {
  font-size: 40px;
  color: rgba(242, 57, 4, 1);
  line-height: 35px;
}
.landing_title {
  font-family: "Prompt", sans-serif;
  font-size: 70px;
  color: rgba(0, 24, 61, 1);
  font-weight: bold;
}
.landing_login a {
  font-family: "Jost", sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  background-color: rgba(0, 24, 61, 1);
  color: rgb(255, 255, 255);
  padding: 6px 44px;
  border-radius: 30px;
  transition: 0.3s all ease-in-out;
}
.landing_login a i {
  margin-left: 3px;
}
.landing_login a:hover {
  background-color: rgba(242, 57, 4, 1);
  color: rgb(255, 255, 255);
  transition: 0.3s all ease-in-out;
}
.login_right {
  width: 45%;
  padding: 0% 8%;
}
.login_logo {
  text-align: center;
  margin-bottom: 20px;
}
.login_txt {
  font-size: 26px;
  font-family: "Jost", sans-serif;
  color: rgba(234, 76, 137, 1);
  text-align: center;
  margin-bottom: 36px;
}
.login_form .invalid-feedback {
  float: left;
  width: 100%;
  margin-top: 10px;
  display: block;
}
.login_form .form-group {
  margin-bottom: 20px;
}
.login_form input[type="text"],
.login_form input[type="password"] {
  border: 1px solid rgba(226, 230, 234, 1);
  border-radius: 5px;
  float: left;
  width: 100%;
  padding: 18px 15px;
  outline: none;
}
.login_form label {
  font-size: 15px;
  color: rgba(105, 105, 105, 1);
  margin-left: 10px;
}
.login_btn {
  text-align: center;
  margin-top: 20px;
}

.login_btn button {
  float: left;
  width: 100%;
  background-color: #00183d;
  border: 1px solid #e2e6ea;
  border-radius: 5px;
  padding: 20px 0px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-family: "Jost", sans-serif;
  transition: 0.3s all ease-in-out;
}
.login_btn button:hover {
  background-color: rgba(242, 57, 4, 1);
  color: rgb(255, 255, 255);
  transition: 0.3s all ease-in-out;
}

.login_form input[type="checkbox"] {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.login_form label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
}

.login_form label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid rgba(43, 55, 62, 1);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 50%;
}

.login_form input[type="checkbox"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
}

.sidebar {
  background-color: rgba(0, 24, 61, 1);
  float: left;
  width: 235px;
  position: fixed;
  left: 0px;
  top: 0px;
}

.sidebar_logo {
  padding: 20px 35px;
}
.sidebar_nav {
  position: fixed !important;
  width: 235px;
  height: calc(100vh - 88px);
  left: 0px;
  top: 88px;
  background-color: #09183d;
}
.sidebar_nav ul li a {
  font-family: "Jost";
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.sidebar_nav ul {
  padding: 0px;
}
.sidebar_nav ul li {
  padding: 15px 0px;
}
.sidebar_nav ul li a {
  font-family: "Jost", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav_icon {
  text-align: right;
  padding: 10px 20px;
  border-radius: 0px 30px 30px 0px;
  width: 40%;
}
.sidebar_nav ul li a:hover .nav_icon {
  background-color: rgba(196, 196, 196, 1);
  transition: 0.3s all ease-in-out;
}
.nav_icon i {
  font-size: 18px;
  color: rgb(255, 255, 255) !important;
}
.nav_txt {
  padding-left: 15px;
  width: 60%;
}
.sidebar_nav ul li .active .nav_icon {
  background-color: rgba(196, 196, 196, 1);
}
.version {
  font-family: "Jost", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 70%;
  padding: 15px 0px;
}
.version img {
  margin-bottom: 10px;
}
.page_container {
  width: 100%;
  padding-left: 235px;
}
.page_head {
  display: flex;
  align-items: center;
  padding: 18px 35px;
  box-shadow: 0px 3px 6px 0px rgb(0 147 148 / 50%);
  position: relative;
}
.page_title {
  font-family: "Jost", sans-serif;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 3px;
  text-transform: uppercase;
}
.profile_icon {
  margin-right: 15px;
  cursor: pointer;
}
.profile_icon img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.filler {
  flex-grow: 1;
}
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgb(255 255 255 / 40%) !important;
}
.profile_name {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.dashboard_drops {
  display: flex;
  align-items: center;
  padding: 17px 36px 17px 36px;
  border-bottom: 1px solid #e6eaed;
}
.drops_name svg {
  color: rgba(0, 147, 148, 1);
  font-size: 10px;
}
.drops_select .css-yk16xz-control {
  background-color: rgba(227, 226, 226, 1) !important;
  border-radius: 12px !important;
}
.drops_select .css-2b097c-container svg {
  color: rgba(0, 147, 148, 1) !important;
  width: 14px;
}
.drops_select .css-1okebmr-indicatorSeparator {
  display: none;
}
.drops_select .css-1uccc91-singleValue {
  font-family: "Jost", sans-serif !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}
.dashboard_drops {
  display: flex;
  align-items: center;
}
.drops_name {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.dashboard_left_head {
  font-family: "Jost", sans-serif;
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.dashboard_cont {
  display: flex;
}
.dashboard_left {
  width: 70%;
  padding: 15px 15px 15px 35px;
}
.dashboard_right {
  width: 30%;
  padding: 15px 10px;
}
.dashboard_right_head {
  font-family: "Jost", sans-serif;
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.dash_more {
  text-align: right;
  position: relative;
}
.dash_more i {
  color: rgba(0, 0, 0, 1);
  font-size: 22px;
  cursor: pointer;
}
.dash_pop {
  position: absolute;
  top: 30px;
  right: 0px;
  background-color: rgb(255,255,255);
  text-align: left;
  box-shadow: 0px 4px 10px rgb(132 132 132 / 35%);
  border-radius: 6px;
  padding: 10px 0px;
  z-index: 9;
}
.dash_pop ul li:first-child {
  border-bottom: 1px solid rgb(234 234 234);
}
.dash_pop ul li button {
  float: left;
  width: 100%;
  padding: 10px 20px;
  border: none;
  background-color: rgb(255,255,255);
  text-align: left;
}


.dash_name {
  font-family: "Noto Serif JP", serif;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dash_check input {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.dash_check label {
  position: relative;
  cursor: pointer;
  float: none !important;
  width: auto !important;
}

.dash_check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.dash_check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 10px;
  width: 9px;
  height: 15px;
  border: solid rgb(0 0 0);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.dash_file {
  margin-top: 45px;
}
.dash_file .upload-btn-wrapper:first-child {
  margin-right: 10px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  text-align: right;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.dash_table tr td {
  font-family: "Noto Serif JP", serif;
  font-size: 23px;
  color: rgba(112, 112, 112, 1);
  padding: 5px;
}
.dash_cont {
  display: flex;
  align-items: center;
}
.dashin {
  display: flex;
  align-items: center;
}
.dash_upload {
  width: 15%;
}
.dash_table {
  width: 85%;
}
.dash_txt {
  width: 70%;
}
.dash_image {
  width: 30%;
}
.dash_image img {
  width: 100%;
}
.dash_box {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 4px 3px 15px 0px rgb(0 0 0 / 16%);
  padding: 20px;
  margin-bottom: 25px;
}
.dash_box.grey {
  background-color: rgba(196, 196, 196, 1);
}
.advertise_box {
  display: flex;
  align-items: center;
  background-color: rgba(226, 229, 33, 1);
  border-radius: 10px;
  padding: 25px 12px;
  margin-bottom: 20px;
  position: relative;
}
.advertise_more {
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
}

.advertise_delete {
  position: absolute;
  top: 40px;
  right: 15px;
  background-color: rgb(255,255,255);
  text-align: left;
  box-shadow: 0px 4px 10px rgb(132 132 132 / 35%);
  border-radius: 6px;
  padding: 10px 20px;
  z-index: 9;
  border: none;
}
.advertise_date {
  width: 40%;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
.advertise_comp {
  width: 60%;
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.advertise_selected {
  width: 70%;
}
.advertise_selected .advertise_date {
  width: 26%;
}
.advertise_selected .advertise_comp {
  width: 50%;
  padding: 0px 10px;
}
.advertise_file {
  width: 24%;
  margin-right: 30px;
}
.advertise_file img {
  width: 124px;
  height: 124px;
}
.advertise_file video {
  width: 124px;
  height: 124px;
}
.advertise_media {
  margin-left: 5px;
}
.advertise_media img, .advertise_media video {
  width: 80px;
  height: 80px;
}
.advertise_selected .advertise_comp {
  font-size: 22px;
}
.dash_btn {
  margin-bottom: 25px;
}
.dash_btn button {
  font-family: "Noto Sans JP";
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1px;
  border-radius: 10px;
  padding: 10px;
  width: 230px;
}
.dash_btn .save {
  border: 1px solid rgba(136, 136, 136, 1);
  background-color: rgb(9 24 61);
  margin-right: 15px;
}
.dash_btn .cancel {
  border: 1px solid rgba(196, 196, 196, 1);
  background-color: rgba(196, 196, 196, 1);
}
.drag_name {
  font-family: "Jost", sans-serif;
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 3px;
}
.drag_tag {
  font-family: "Noto Serif JP";
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.drag_cont label {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  font-weight: normal !important;
}
.drag_cont label span {
  color: rgba(0, 98, 112, 1);
}
.drag_cont label img {
  margin-right: 25px;
}
.drops_select .css-2b097c-container {
  width: 210px;
}
.drops_left {
  width: 240px;
}
.drops_right {
  width: 240px;
}
.drops_select {
  font-family: "Jost", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.3px;
  background-color: rgba(227, 226, 226, 1);
  border-radius: 12px;
  border: none;
  padding: 10px 20px;
  width: 100%;
}
.drops_select select {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
.drag_cont .dzu-dropzone {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 4px 3px 15px 0px rgb(0 0 0 / 16%);
  border: none;
}

.drag_cont .dzu-dropzone::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background-color: #fff;
}

.drag_cont .dzu-dropzone::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}

.drag_cont .dzu-dropzone::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #fff;
}
.drop_border {
  border: 2px solid rgba(67, 74, 74, 1);
  border-radius: 10px;
  padding: 2% 10%;
}

.profile_left {
  width: 25%;
}
.profile_left ul {
  padding: 0px;
  margin: 0px;
}
.profile_left ul li {
  list-style-type: none;
  float: left;
  width: 100%;
}
.profile_left ul li a {
  font-family: "Jost", sans-serif;
  font-size: 14px;
  color: rgba(0, 147, 148, 1);
  letter-spacing: 0.3px;
  text-decoration: none;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.profile_left ul li.active a {
  color: rgba(0, 0, 0, 1);
}
.profile_left ul li a i {
  width: 25%;
  padding: 15px 20px;
  text-align: center;
}
.profile_left ul li a span {
  border-bottom: 1px solid rgba(0, 224, 199, 1);
  width: 75%;
  padding: 15px 0px;
}
.profile_main {
  display: flex;
}

.profile_head {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 18px;
}
.profile_right {
  width: 75%;
  padding: 15px 25px;
  border-left: 1px solid rgba(0, 224, 199, 1);
  min-height: calc(100vh - 91px);
}
.profile_image {
  padding: 28px 0px 45px 0px;
  text-align: center;
}
.profile_form .form-group {
  margin-bottom: 15px;
}
.profile_form label {
  font-family: "Noto Sans JP";
  font-size: 14px;
  color: rgba(67, 74, 74, 1);
  letter-spacing: 1px;
  float: left;
  width: 100%;
  margin-bottom: 6px;
}
.profile_form input {
  border: 1px solid rgba(67, 74, 74, 1);
  border-radius: 6px;
  float: left;
  width: 100%;
  outline: none;
  padding-right: 10px;
  padding-left: 10px;
}
.profile_image span {
  display: inline-block;
  position: relative;
}
.profile_upload {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  right: -10px;
  bottom: -2px;
}
.profile_upload input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.profile_btn {
  margin-top: 24px;
}
.profile_btn button {
  font-family: "Prompt", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  background-color: rgba(196, 196, 196, 1);
  border: none;
  width: 230px;
  padding: 8px;
  border-radius: 30px;
}
.security_head {
  display: flex;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
  border-bottom: 1px solid rgba(0, 224, 199, 1);
  border-radius: 5px 5px 0px 0px;
  padding: 18px 28px;
}
.security_title {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 3px;
  font-weight: 700;
}
.security_icon i {
  font-size: 15px;
}
.security_title span {
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  font-weight: normal;
}
.security_name {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
}
.security_box {
  border: 1px solid rgba(0, 224, 199, 1);
  border-radius: 5px;
  margin-bottom: 15px;
}
.security_form label {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.security_form input {
  border: 1px solid rgba(67, 74, 74, 1);
  border-radius: 6px;
  float: left;
  width: 100%;
  outline: none;
  padding-right: 10px;
  padding-left: 10px;
}
.security_form .form-group {
  margin-bottom: 10px;
}
.security_cont {
  padding: 15px 28px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
}
.security_btn {
  margin-top: 10px;
}
.security_btn button {
  font-family: "Prompt", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  background-color: rgba(196, 196, 196, 1);
  border: none;
  width: 230px;
  padding: 8px;
  border-radius: 30px;
}

.confirmation_form .form-group {
  margin-bottom: 10px;
}
.confirmation_form label {
  font-family: "Noto Sans JP";
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.confirmation_form input {
  border: 1px solid rgba(67, 74, 74, 1);
  border-radius: 6px;
  float: left;
  width: 100%;
  outline: none;
  padding-right: 15px;
  padding-left: 15px;
}
.profile_left ul li.active a::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.psw {
  display: flex;
  padding: 25px 30px;
}
.psw_icon {
  margin-right: 15px;
}
.psw_icon i {
  color: rgba(0, 147, 148, 1);
  font-size: 18px;
}
.psw_cont {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
}
.psw_cont span {
  font-size: 14px;
  font-weight: 700;
}
.security_icon {
  cursor: pointer;
}
.setting_main {
  padding: 20px 60px;
}
.setting_head {
  font-family: "Jost", sans-serif;
  font-size: 22px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 28px;
  font-weight: 400;
}
.setting_head select {
  font-size: 16px;
  padding: 4px 10px;
  width: 200px;
  border: 1px solid rgba(67, 74, 74, 1);
  background-color: transparent;
  border-radius: 7px;
  margin-left: 30px;
  outline: none;
}
.setting_cont {
  padding-right: 20%;
}
.setting_form .form-group {
  margin-bottom: 26px;
}
.setting_form label {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  float: left;
  width: 45%;
}
.setting_form input, .setting_form select {
  border: 1px solid rgba(67, 74, 74, 1);
  border-radius: 7px;
  float: left;
  width: 55%;
  font-family: "Jost", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  background-color: transparent;
  letter-spacing: 1px;
  padding: 5px 10px;
  outline: none;
}

.setting_form .css-yk16xz-control {
  background-color: transparent !important;
  border: 1px solid rgba(67, 74, 74, 1) !important;
  font-family: "Jost", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
}
.setting_btn {
  margin-top: 54px;
}
.setting_btn button {
  font-family: "Prompt", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  background-color: rgba(196, 196, 196, 1);
  border: none;
  width: 230px;
  padding: 8px;
  border-radius: 10px;
}
.privacy_main {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  line-height: 24px;
  padding: 60px 70px;
}
.terms_main {
  padding: 52px 90px;
}
.terms_main ul {
  padding: 0px;
  margin: 0px;
}
.terms_main ul li {
  list-style-type: none;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}
.term_left {
  width: 25%;
}
.term_right {
  width: 75%;
}
.contact_main {
  padding: 60px 80px;
}
.contact_head {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 30px;
  margin-bottom: 30px;
}
.contact_tag {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  border-bottom: 1px solid rgb(0, 0, 0);
  padding-bottom: 12px;
  margin-bottom: 13px;
}
.contact_desc {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}

.contact_flex {
  display: flex;
  margin-bottom: 60px;
}
.contact_box {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
  padding: 35px;
  width: 50%;
  margin: 0px 24px;
  text-align: center;
}

.contact_form .form-group {
  margin-bottom: 25px;
}
.contact_form label {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  float: left;
  width: 30%;
}
.contact_form label span {
  font-size: 8px;
  color: rgba(255, 0, 0, 1);
  margin-left: 5px;
}
.contact_form input {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 6px;
  outline: none;
  float: left;
  width: 70%;
  padding-right: 10px;
  padding-left: 10px;
}
.contact_form textarea {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 6px;
  outline: none;
  float: left;
  width: 70%;
  height: 190px;
  padding-right: 10px;
  padding-left: 10px;
}
.contact_btn button {
  font-family: "Prompt", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  background-color: rgba(196, 196, 196, 1);
  border: none;
  width: 230px;
  padding: 8px;
  border-radius: 10px;
}
.contact_btn {
  text-align: center;
  margin-top: 25px;
}
.sales_option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e6dfdf;
  border-bottom: 1px solid #e6dfdf;
  margin-bottom: 10px;
}
.sales_main {
  background-color: rgba(253, 247, 247, 1);
  min-height: calc(100vh - 91px);
  padding: 40px 85px;
}
.sales_option img {
  margin-right: 20px;
}
.sales_option .css-1uccc91-singleValue {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgba(0, 147, 148, 1);
  letter-spacing: 1px;
}
.sales_option .css-2b097c-container {
  width: 130px;
}
.sales_option .css-2b097c-container svg {
  color: rgba(0, 147, 148, 1);
  width: 15px;
  height: 15px;
}
.sales_option .css-yk16xz-control {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.sales_option .css-1okebmr-indicatorSeparator {
  display: none;
}
.select_group {
  display: flex;
  align-items: center;
}
.select_group img {
  margin-right: 2px;
}
.select_group:last-child {
  margin-left: 25px;
}
.sales_table table thead tr th {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgba(0, 147, 148, 1);
  letter-spacing: 1px;
  border: none;
  font-weight: 500;
}
.sales_table tbody tr {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9px;
}
.sales_table table tbody tr td {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
}
.sales_table table tr td span {
  color: rgba(0, 147, 148, 1);
}

.sales_table table tbody tr td:nth-child(2),
.sales_table table tbody tr td:nth-child(3) {
  font-family: "Noto Sans JP", sans-serif;
}
.sales_table table tr td {
  vertical-align: middle;
  padding-top: 23px;
  padding-bottom: 23px;
}
.price_tag {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 13px;
}
.price_rate {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
}
.total_box {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9px;
  box-shadow: 5px 7px 6px 0px rgb(0 0 0 / 16%);
  padding: 20px 15px;
  display: flex;
  align-items: center;
  width: 200px;
  float: right;
}
.sales_total {
  margin-top: 20px;
}
.advertise_cont ul {
  padding: 0px;
  margin: 0px;
}
.advertise_cont ul li {
  list-style-type: none;
}

.contact_form .invalid-feedback {
  float: left;
  width: 100%;
  padding-left: 30%;
  margin-top: 10px;
  display: block;
}
input.is-invalid,
textarea.is-invalid {
  border: 1px solid rgb(220 53 69);
}
.spinner-border {
  margin-right: 10px;
}

.alert-danger {
  position: fixed !important;
  bottom: 50px;
  z-index: 9;
  right: 50px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 60%);
}
.app {
  display: none;
}
.app2 {
  display: block;
  position: absolute;
  right: 30px;
  top: 95px;
  width: 150px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 60%);
  text-align: center;
  border-radius: 4px;
  transition: all .5s ease-in-out;
  z-index: 9;
}
.app2 a {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
.app2 a:hover {
  color: rgb(0 0 0);
}

.profile_icon button {
  width: 100%;
  padding: 15px 5px;
  background-color: transparent;
  border: none;
  font-family: "Jost",sans-serif;
  font-size: 16px;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.dashboard_left ul {
  padding: 0px;
  margin: 0px;
  float: left;
  width: 100%;
}
.dashboard_left ul li {
  list-style-type: none;
  float: left;
  width: 100%;
}
.drag_box {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 4px 3px 15px 0px rgb(0 0 0 / 16%);
  border: none;
  padding: 40px 150px;
  margin-bottom: 20px;
}

.upload_btn_wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}
.upload_btn_wrapper input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.upload_txt {
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-family: 'Jost', sans-serif;
  text-align: center;
  padding: 30px 15px;
  border: 2px solid rgba(67, 74, 74, 1);
  border-radius: 10px;
}
.upload_txt span {
  color: rgba(0, 98, 112, 1);
}
.upload_txt img {
  margin-right: 25px;
}
.dash_submit {
  margin-top: 25px;
}
.dash_submit button {
  font-family: "Noto Sans JP";
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1px;
  border-radius: 10px;
  padding: 10px;
  width: 230px;
}
.dash_submit .save {
  border: 1px solid rgba(136, 136, 136, 1);
  background-color: rgb(9 24 61);
  margin-right: 15px;
}
.dash_submit .cancel {
  border: 1px solid rgba(196, 196, 196, 1);
  background-color: rgba(196, 196, 196, 1);
}


.dash_table tr td input {
  width: 100% !important;
  outline: none;
  padding: 5px 20px;
  height: auto !important;
  background-color: rgba(232, 210, 210, 1);
  border: none;
  color: rgba(112, 112, 112, 1);
  font-size: 20px;
  letter-spacing: 1px;
  border-radius: 5px;
}
.dash_table tr td input::-webkit-input-placeholder {
  font-size: 16px;
}
.dash_table tr td input:-ms-input-placeholder {
  font-size: 16px;
}
.dash_table tr td input::placeholder {
  font-size: 16px;
}
.dash_table tr td input[type=number]::-webkit-inner-spin-button, 
.dash_table tr td input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}


.alert-danger, .alert-success {
  position: fixed !important;
  bottom: 50px;
  z-index: 9;
  right: 50px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 60%);
  padding-right: 3rem !important;
}
.alert-success::before {
  position: absolute;
  content: '\f058';
  font-family: 'Font awesome 5 Free';
  right: 1rem;
  font-size: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: 600;
}
.alert-danger::before {
  position: absolute;
  content: '\f057';
  font-family: 'Font awesome 5 Free';
  right: 1rem;
  font-size: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: 600;
}
.edit_upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-family: 'Jost', sans-serif;
  text-align: center;
  padding: 15px 5px;
  border: 2px solid rgba(67, 74, 74, 1);
  border-radius: 10px;
  margin-top: 10px;
}
.edit_upload input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.edit_txt img {
  width: auto;
  margin-right: 15px;
}
.myprofile {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 60%);
}
.profile_form .invalid-feedback {
  display: block;
}
.profile_invalid {
  display: block !important;
}
.security_form .invalid-feedback {
  display: block;
}
.setting_form select.is-invalid {
  border: 1px solid #dc3545;
}
.setting_head span {
  float: left;
}
.setting_head select {
  width: 25%;
}
.setting_form .invalid-feedback {
  display: block;
  font-size: 14px;
  float: left;
  width: 100%;

}
.setting_head .invalid-feedback {
  padding-left: 185px;
}
.setting_cont .invalid-feedback {
  padding-left: 45%;
}
.sales_table {
  overflow-x: auto;
  overflow-y: visible;
}
.sales_table .table {
  margin-bottom: 0px;
}
.pagination {
  margin-top: 20px;
}
.pagination .react-pagination-js-bootstrap {
  width: 100%;
}
.pagination .react-pagination-js-bootstrap ul {
  float: right;
  background-color: rgb(255,255,255);
}
.invoice_btn {
  font-family: "Jost", sans-serif;
  font-size: 10px;
  color: rgb(255 255 255) !important;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: rgba(0, 147, 148, 1);
  padding: 5px;
  border-radius: 2px;
  margin-right: 30px;
}
.invoice_btn i {
  margin-right: 2px;
}
.eQmQXs {
  z-index: 9;
}
.dash_table .invalid-feedback {
  display: block;
  font-size: 14px;
}
.dash_table tr td input.is-invalid {
  border: 1px solid #dc3545 !important;
}
.react-confirm-alert-body {
  font-family: "Noto Sans JP",sans-serif!important;
}
.react-confirm-alert-button-group {
  margin-top: 30px!important;
}
.react-confirm-alert-button-group button:first-child {
  background-color: rgb(48 133 214);
}
.react-confirm-alert-button-group button {
  color: rgb(255,255,255);
  font-weight: 600;
  font-family: "Noto Sans JP",sans-serif!important;
}
.react-confirm-alert-button-group button:last-child {
  background-color: rgb(221 51 51);
}
.loader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgb(255 255 255 / 90%);
  z-index: 999;
}
.loader img, .loader svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


/* Media Query */

@media (max-width: 767px) {
  .landing_middle img {
    width: 100%;
  }
  .landing_right img {
    width: 100%;
  }
  .landing_right {
    margin: 30px 0px;
  }
  .landing_title {
    line-height: 75px;
    margin: 40px 0px;
  }
  .landing_cont {
    padding: 20px 15px;
  }
  .landing_cont .row::before {
    left: 0px;
    top: 15%;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    font-size: 80px;
    line-height: 80px;
  }
  .dill {
    left: -20px;
  }
  .login_left {
    display: none;
  }
  .login_right {
    width: 100%;
    padding: 10% 8%;
  }
}

@media (min-width: 768px) {
  .landing_cont .row {
    display: flex;
    align-items: center;
  }
  .login_container {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .landing_cont {
    position: relative;
    height: 80vh;
  }
  .landing_cont .container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .login_left {
    width: 55%;
  }
  .login_left img {
    width: 100%;
    height: 100vh;
  }
  .profile_cont {
    width: 50%;
  }
  .security_cont {
    width: 50%;
  }
  .contact_form {
    padding: 0% 12%;
  }
  .small_ip {
    width: 45% !important;
  }
  .medium_ip {
    width: 58% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .landing_middle img {
    width: 100%;
  }
  .landing_right img {
    width: 100%;
  }
  .landing_title {
    font-size: 40px;
  }
  .landing_cont .row::before {
    left: 5px;
    top: 80%;
    font-size: 45px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .landing_middle img {
    width: 100%;
  }
  .landing_right img {
    width: 100%;
  }
  .landing_title {
    font-size: 40px;
  }
  .landing_cont .row::before {
    left: 5px;
    top: 80%;
    font-size: 45px;
  }
}

